import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import LinkButton from '../../LinkButton/LinkButton'
import { pokerOneLinksHomePage } from '../../../one-links'

const MobileScreen = ({ lang = 'eng' }: { lang?: 'eng' | 'hindi' }) => {
  const title =
    lang === 'eng' ? (
      <>
        {' '}
        Claim Your Victory <br />
        on India's Lowest <br />
        POKER Rake Tables!
      </>
    ) : (
      <>
        भारत का सबसे लोकप्रिय <br /> पोकर क्लब
      </>
    )

  const subHeading = lang === 'eng' ? 'Jeeto Befikar!' : 'जीतो बेफिकर!'

  const buttonText = lang === 'eng' ? 'Play Now' : 'अभी खेलें'

  const tagLine =
    lang === 'eng'
      ? 'Trusted choice of more than 2 crore Poker Players'
      : '2 करोड़ से अधिक पोकर खिलाड़ियों की विश्वसनीय पसंद'

  return (
    <div className="d-lg-none">
      <div className="inner-content">
        <StaticImage
          loading="eager"
          src="../../../../images/index/MainBanner/vedaa-mobile.png"
          alt="main-mobile-banner"
          className="mobile-image"
        />
      </div>
      <div className="page-description">
        {/* <h1 className="mobile-heading">{title}</h1> */}
        {/* <p className="sub-heading">{subHeading}</p> */}
        <LinkButton btnFor="mobile" oneLink={pokerOneLinksHomePage}>
          {buttonText}
        </LinkButton>
        {/* <div className="image-bottom-line">
            <span
              style={{
                width: '15px',
              }}
            >
              <img src={ClubSvg} alt="svg" />
            </span>
            <p>{tagLine}</p>
            <span
              style={{
                width: '15px',
              }}
            >
              <img src={ClubSvg} alt="svg" />
            </span>
          </div> */}
      </div>
    </div>
  )
}

export default MobileScreen
