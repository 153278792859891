import React, { useEffect, useState } from 'react'
import './MainBanner.scss'
import MobileScreen from './MobileScreen'
import DesktopScreen from './DesktopScreen'

const MainBanner = ({ lang = 'eng' }: { lang?: 'eng' | 'hindi' }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Set initial value based on current window size
      setIsMobile(window.innerWidth <= 960)

      const handleResize = () => {
        setIsMobile(window.innerWidth <= 960)
      }

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  const homePageContent = (
    <>
      {/* {isMobile ? <MobileScreen lang={lang} /> : <DesktopScreen lang={lang} />} */}
      <DesktopScreen lang={lang} />
      <MobileScreen lang={lang} />
    </>
  )

  return <div id="banner-container">{homePageContent}</div>
}

export default MainBanner
