import React from 'react'
import './Trust-Flow-Widget.scss'

const TrustFlowWidget = ({ lang = 'eng' }: { lang?: 'eng' | 'hindi' }) => {
  const data = [
    {
      data: '₹400 Cr+',
      desc: 'Winnings in 1yr',
    },
    {
      data: '3 Cr+',
      desc: 'games played',
    },
    {
      data: '10 Lakh+',
      desc: 'happy users',
    },
    {
      data: '5+ Yrs',
      desc: 'of poker play',
    },
  ]

  const hindiData = [
    {
      data: '₹400 करोड़+',
      desc: '1 वर्ष में जीत',
    },
    {
      data: '3 करोड़+',
      desc: 'खेली गई गेम्स',
    },
    {
      data: '10 लाख+',
      desc: 'खुश उपयोगकर्ता',
    },
    {
      data: '5+ वर्ष',
      desc: 'पोकर खेलने का अनुभव',
    },
  ]
  const renderData = lang === 'eng' ? data : hindiData
  return (
    <div id="state-container">
      <div className="widget-container">
        {renderData.map((el, idx) => {
          return (
            <div key={el.data + idx} className="widget-content">
              <p className="state-heading">{el.data}</p>
              <p className="desc">{el.desc}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TrustFlowWidget
